import axios from "axios";
import api from "../constants/api-world";

export default class APIWORLD {
  async getPaises() {
    return axios.get(api, {
      params: {
        ot: "get_countries",
      },
    });
  }

  async getEstadosDelPais(id_pais) {
    return axios.get(api, {
      params: {
        ot: "get_states_from_country",
        id_country: id_pais,
      },
    });
  }

  async getCiudadesDelEstado(id_estado) {
    return axios.get(api, {
      params: {
        ot: "get_cities_from_state",
        id_state: id_estado,
      },
    });
  }
}
