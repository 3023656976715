import "moment/locale/es";
import moment from "moment";
moment.lang("es");

const useFecha = () => {
  const convertirFormatoRegular = (fecha) => {
    let fechaFormat = `${moment(fecha).format("DD")} de ${moment(fecha).format(
      "MMMM"
    )} de ${moment(fecha).format("YYYY")}`;

    return fechaFormat;
  };

  const convertirFormatoAbreviado = (fecha) => {
    let fechaFormat = `${moment(fecha).format("DD")}/${moment(fecha).format(
      "MMM"
    )}/${moment(fecha).format("YY")}`;

    return fechaFormat.toUpperCase().replace(".", "");
  };

  const timeAgo = (fecha) => {
    let time = moment(fecha).fromNow();

    return time;
  };

  const calculateEdad = (fecha_nacimiento) => {
    var years = moment().diff(fecha_nacimiento, "years");

    return `${years} ${years <= 1 ? "año" : "años"}`;
  };

  const compararDiferenciaDias = (dia1, dia2) => {
    var a = moment(dia1);
    var b = moment(dia2);
    return a.diff(b, "days");
  };

  return {
    convertirFormatoRegular,
    compararDiferenciaDias,
    timeAgo,
    calculateEdad,
    convertirFormatoAbreviado,
  };
};

export default useFecha;
